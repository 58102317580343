// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2021-album-list-js": () => import("./../../../src/pages/2021-album-list.js" /* webpackChunkName: "component---src-pages-2021-album-list-js" */),
  "component---src-pages-2022-album-list-js": () => import("./../../../src/pages/2022-album-list.js" /* webpackChunkName: "component---src-pages-2022-album-list-js" */),
  "component---src-pages-2022-first-album-listen-list-js": () => import("./../../../src/pages/2022-first-album-listen-list.js" /* webpackChunkName: "component---src-pages-2022-first-album-listen-list-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-temp-js": () => import("./../../../src/pages/temp.js" /* webpackChunkName: "component---src-pages-temp-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

